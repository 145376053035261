
























import { Vue, Component } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class PrivacyPolicy extends Vue {
	/* mounted() {
		const script = document.createElement('script');
		script.src = 'https://matomo.meeting-navigator.com/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&backgroundColor=FFFFFF&fontColor=212529&fontSize=10pt&fontFamily=ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji&showIntro=1';
		script.async = true;
		document.head.appendChild(script);
	}*/
}
